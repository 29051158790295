import React, {useState} from 'react';
// import sections
import {LOCALES} from '../i18n/index';
import sellWithGrabbi from "../assets/images/SellWithGrabbi.png";
import introBg from "../assets/images/vendors_header_bg.jpeg";
import notification from "../assets/images/vendor_notification.png";
import preparation from "../assets/images/vendor_preparation.png";
import pickup from "../assets/images/vendor_pickup.jpg";
import translate from "../i18n/translate";
import VendorsSection from "../components/sections/VendorsSection";
import LayoutVendors from "../layouts/LayoutVendors";
import ContactFormVendors from "../components/sections/ContactFormVendors";
import ContactFormVendorsAlternative from "../components/sections/ContactFormVendorsAlternative";

const BecomeAPartner = () => {

    const [locale, setLocale] = useState(LOCALES.DEUTSCH);

    function changeLanguage(lang) {
        setLocale(lang)
    }

    const [modalVisible, setModalVisible] = useState(false);

    function toggleModal() {
        setModalVisible(!modalVisible)
    }

    return (
        <LayoutVendors locale={locale} language={changeLanguage}>
            <div id="become-a-partner">
                <section id="header">
                    <div className="intro-container">
                        <div className="col mx-md-4">
                            <div className="intro-container-bg justify-content-xl-center" style={{backgroundImage: `url(${introBg})`}}>
                                <div className="container align-self-center mt-5 mx-lg-5">
                                    <div className="row background-hero-header-row">
                                        <div className="col-md-12 ms-xl-4">
                                            <div
                                                className=" text-center text-md-start col-12 m-auto background-hero-header-content position-relative"
                                                style={{zIndex: 2}}>
                                                {/*<div className="col-md-6 d-blocl d-md-none my-5">*/}
                                                {/*    <img src={appShowcase} alt={"Grabbi app"} className="grabbi-intro-image-sm"/>*/}
                                                {/*</div>*/}
                                                <h1 className="mt-0 mb-5 reveal-from-bottom text-light header-hero-text"
                                                    data-reveal-delay="200">
                                                    {translate('vendor.header.h1')}
                                                </h1>
                                                <button onClick={toggleModal}>
                                                    {translate('vendor.header.button')}
                                                </button>
                                            </div>
                                        </div>
                                        {/*<div className="col-md-6 d-none d-md-block">*/}
                                        {/*    <img src={appShowcase} alt={"Grabbi app"} className="grabbi-intro-image"/>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>

                            <ContactFormVendors modalVisible={modalVisible} toggleModal={toggleModal}/>
                        </div>

                        {/*<video autoPlay controls={false} src={require('../assets/videos/bg_partner_section.mp4')} />*/}
                        {/*<iframe src="https://www.youtube.com/embed/WZootxMzuoo?autoplay=1&mute=1&loop=1&playlist=WZootxMzuoo"/>*/}
                        {/*<div className="black-overlay"></div>*/}

                    </div>

                    {/*<div className="position-absolute top-50 header-overlay">*/}
                    {/*    */}
                    {/*</div>*/}
                </section>

                <VendorsSection language={locale}/>

                <section id="vendors-how-it-works">
                    <div className="full-screen-height reveal-from-bottom">
                        <div className="container py-5">
                            <div className="row my-4 px-lg-4 mb-5">
                                <h4 className="text-uppercase" style={{color: "yellow"}}>
                                    {translate("vendor.why-us.how-it-works.h4")}
                                </h4>

                                    {
                                        locale === "de-de" ? (
                                            <h1>
                                                Der zusätzliche Aufwand für Dich
                                                <span style={{color: "yellow"}}> bleibt minimal</span>
                                            </h1>
                                        ) : (
                                            <h1>
                                                The additional effort for you
                                                <span style={{color: "yellow"}}> remains minimal</span>
                                            </h1>
                                        )
                                    }

                                <p>
                                    {translate("vendor.why-us.how-it-works.p")}
                                </p>
                            </div>

                            <div className="row justify-content-center mx-5">
                                <div className="col-xl-4 col-lg-6 col-12 mb-4 mb-xl-0">
                                    <div className="card">
                                        <div className="vendor-how-to-box">
                                            <img src={notification}/>
                                            <h2>
                                                <span>1.</span>
                                                <br/>
                                                {translate("vendor.why-us.how-it-works.card1.title")}
                                            </h2>
                                        </div>
                                        <div className="vendor-how-to-content-box">
                                            <p>
                                                {translate("vendor.why-us.how-it-works.card1.p")}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-6 col-12 mb-4 mb-xl-0">
                                    <div id="custom-card" className="container">
                                        <div className="card">
                                            <div className="vendor-how-to-box">
                                                <img src={preparation}/>
                                                <h2>
                                                    <span>2.</span>
                                                    <br/>
                                                    {translate("vendor.why-us.how-it-works.card2.title")}
                                                </h2>
                                            </div>
                                            <div className="vendor-how-to-content-box">
                                                <p>
                                                    {translate("vendor.why-us.how-it-works.card2.p")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-6 col-12 mb-4 mb-xl-0">
                                    <div id="custom-card" className="container">
                                        <div className="card">
                                            <div className="vendor-how-to-box">
                                                <img src={pickup}/>
                                                <h2>
                                                    <span>3.</span>
                                                    <br/>
                                                    {translate("vendor.why-us.how-it-works.card3.title")}
                                                </h2>
                                            </div>
                                            <div className="vendor-how-to-content-box">
                                                <p>
                                                    {translate("vendor.why-us.how-it-works.card3.p")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section id="cta-vendors">
                    <div className="container my-4">
                        <div className="row m-auto mt-5 mb-lg-5" style={{maxWidth: "1200px"}}>
                            <h4 className="grabbi-color-1 text-uppercase mt-2">
                                {translate("vendor.why-us.contact-from.h4")}
                            </h4>
                            <h1 className="fw-500">
                                {translate("vendor.why-us.contact-from.h1.1")}
                                <span style={{color: "#6C4480"}}>{translate("vendor.why-us.contact-from.h1.2")}</span>
                            </h1>
                        </div>
                        <div className="row m-auto" style={{maxWidth: "1200px"}}>
                            <div className="col-lg-6 d-lg-none d-block mb-4 p-4">
                                <img src={sellWithGrabbi} alt="Sell with Grabbi" className="m-auto"/>
                            </div>
                            <div className="col-lg-6 offset-lg-0 col-md-8 offset-md-2 mb-5">
                                <ContactFormVendorsAlternative/>
                            </div>
                            <div className="col-lg-6 d-none d-lg-block">
                                <img src={sellWithGrabbi} alt="Sell with Grabbi" className="m-auto"/>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </LayoutVendors>
    )
}

export default BecomeAPartner;