export const clickButton = (title, location) => {
    const data = {
        event: 'click_button',
        title,
        location
    };
    window.dataLayer.push(data)
}

export const chooseLanguage = (language) => {
    const data = {
        event: 'choose_language',
        language,
    };
    window.dataLayer.push(data)
}