import React from 'react';
import classNames from 'classnames';
import {SectionSplitProps} from '../../utils/SectionProps';
import Parallax from "react-rellax";
import translate from "../../i18n/translate";
import withoutGrabbi from "../../assets/images/map_ohne_grabbi.png";
import withGrabbi from "../../assets/images/map_mit_grabbi.png";

const propTypes = {
    ...SectionSplitProps.types
}

const defaultProps = {
    ...SectionSplitProps.defaults
}

const VendorsSection = ({
                            className,
                            topOuterDivider,
                            bottomOuterDivider,
                            topDivider,
                            bottomDivider,
                            hasBgColor,
                            invertColor,
                            invertMobile,
                            invertDesktop,
                            alignTop,
                            imageFill,
                            language,
                            ...props
                        }) => {

    const innerClasses = classNames(
        'features-split-inner section-inner',
        topDivider,
        bottomDivider
    );


    return (
        <section id="what-we-do">

            {/*EXPAND YOUR REACH PART*/}
            <div className="container-sm full-screen-height">
                <div className={innerClasses} style={{zIndex: 2}}>
                    <div className="hero-figure reveal-from-bottom illustration-element-01 app-showcase"
                         data-reveal-value="20px" data-reveal-delay="800">

                        {/* WITHOUT GRABBI */}
                        <div className="container mt-md-4 mb-5">
                            <div className="row align-items-center">

                                {/*IMAGE XL*/}
                                <div
                                    className="col-xl-6 col-12 app-showcase-image d-flex pb-xl-0 pb-4 d-none d-xl-block">
                                    <img src={withoutGrabbi} alt={"Grabbi app"}
                                         className="ms-auto me-5 align-self-center p-lg-2"
                                    />
                                </div>

                                {/*TEXT SECTION*/}
                                <div
                                    className="pe-lg-5 ps-lg-0 col-xl-6 text-lg-start align-self-center text-center d-flex"
                                    style={{maxWidth: 940}}
                                >
                                    <div
                                        className="row m-auto justify-content-start align-self-center text-start mt-4 mt-lg-auto ps-lg-3"
                                        style={{maxWidth: 1000}}>
                                        <h4 className="mb-4 text-uppercase" style={{color: "#42125C"}}>
                                            {translate("vendor.why-us.h4.1")}
                                        </h4>

                                        {/*TITLE WITHOUT GRABBI*/}
                                        {language === "de-de"
                                            ? <h1 className="mb-4">
                                                Dein Kund:innenradius
                                                <strong className="grabbi-color-1"> OHNE </strong>
                                                Grabbi
                                            </h1>
                                            : <h1 className="mb-4">
                                                Your customer reach
                                                <strong className="grabbi-color-1"> WITHOUT </strong>
                                                Grabbi
                                            </h1>
                                        }

                                        {/*IMAGE MOBILE VIEW*/}
                                        <img src={withoutGrabbi} alt={"Grabbi app"}
                                             className="m-auto pb-5 pt-4 align-self-center p-xl-2 d-block d-xl-none"
                                             style={{maxHeight: 540, width: "auto"}}
                                        />

                                        {/*PARAGRAPH WITHOUT GRABBI*/}
                                        <div className="col-md-12 what-we-do-bullets">
                                            <div className="mb-4 text-center text-lg-start">
                                                <span className="text-start">
                                                    {translate('vendor.why-us.p.1')}
                                                </span>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>

                        {/* WITH GRABBI */}
                        <div className="container mt-md-4 mb-5">
                            <div className="row align-items-center">

                                {/*TEXT SECTION*/}
                                <div
                                    className="ps-xl-5 pe-lg-0 col-xl-6 text-lg-start align-self-center text-center pe-lg-4 d-flex"
                                    style={{maxWidth: 940}}>
                                    <div
                                        className="row m-auto justify-content-start align-self-center text-start mt-4 mt-lg-auto"
                                        style={{maxWidth: 1000}}>
                                        {/*TITLE WITHOUT GRABBI*/}
                                        {language === "de-de"
                                            ? <h1 className="mb-4">
                                                Dein Kund:innenradius
                                                <strong className="grabbi-color-1"> MIT </strong>
                                                Grabbi
                                            </h1>
                                            : <h1 className="mb-4">
                                                Your customer reach
                                                <strong className="grabbi-color-1"> WITH </strong>
                                                Grabbi
                                            </h1>
                                        }

                                        {/*IMAGE MOBILE*/}
                                        <img src={withGrabbi} alt={"Grabbi app"}
                                             className="m-auto pb-5 pt-4 align-self-center p-xl-2 d-block d-xl-none"
                                             style={{maxHeight: 540, width: "auto"}}
                                        />

                                        {/*PARAGRAPH WITH GRABBI*/}
                                        <div className="col-md-12 what-we-do-bullets">
                                            <div className="mb-4 text-center text-lg-start">
                                                <span className="text-start">
                                                    {translate('vendor.why-us.p.2')}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {/*IMAGE*/}
                                <div
                                    className="col-xl-6 col-12 app-showcase-image d-flex pb-xl-0 pb-4 d-none d-xl-block">
                                    <img src={withGrabbi} alt={"Grabbi app"}
                                         className="me-auto ms-5 align-self-center p-lg-2"
                                    />
                                </div>
                            </div>

                        </div>


                    </div>


                    {/*THREE COLUMNS BENEFITS*/}
                    <div className="container mb-3" style={{zIndex: 2, maxWidth: "1200px"}}>
                        <div className="row align-items-start">
                            <div className="col-lg-4 text-center">
                                <img className="img-columns" src={require("../../assets/images/Grabbi_Location.png")}/>
                                <div className="txt-columns">
                                    <h4>{translate('vendor.why-us.column1.h1')}</h4>
                                    <p>{translate('vendor.why-us.column1.p')}</p>
                                </div>
                            </div>
                            <div className="col-lg-4 text-center">
                                <img className="img-columns" src={require("../../assets/images/Grabbi_Abholung.png")}/>
                                <div className="txt-columns">
                                    <h4>{translate('vendor.why-us.column2.h1')}</h4>
                                    <p>{translate('vendor.why-us.column2.p')}</p>
                                </div>
                            </div>
                            <div className="col-lg-4 text-center">
                                <img className="img-columns" src={require("../../assets/images/Grabbi_Risk_Free.png")}/>
                                <div className="txt-columns">
                                    <h4>{translate('vendor.why-us.column3.h1')}</h4>
                                    <p>{translate('vendor.why-us.column3.p')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*UPWARD CUBES*/}
            <div className="area">
                <ul className="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>
        </section>

    );
}

VendorsSection.propTypes = propTypes;
VendorsSection.defaultProps = defaultProps;

export default VendorsSection;