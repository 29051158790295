import React from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import Image from '../../elements/Image';

const Logo = ({
                  className,
                  backgroundTransparent,
                  ...props
              }) => {

    const classes = classNames(
        'brand',
        className,
        'ps-lg-4'
    );
    {console.log(backgroundTransparent, "TRUE?")}

    return (
        <div
            {...props}
            className={classes}
        >
            <h1 className="m-0">
                <Link to="/">
                    <Image
                        src={
                            !backgroundTransparent
                                ? require('./../../../assets/images/header_logo.png')
                                : require('./../../../assets/images/header_logo_alt.png')
                        }
                        alt="Grabbi"
                        className="header-logo"
                    />
                </Link>
            </h1>
        </div>
    );
}

export default Logo;