import React from 'react';
import PropTypes from 'prop-types';
import {SectionProps} from '../../utils/SectionProps';
import MailchimpSubscribe from "react-mailchimp-subscribe"
import translate from "../../i18n/translate";
import {AppStoreIcons} from "../elements/AppStoreIcons";


const propTypes = {
    ...SectionProps.types,
    split: PropTypes.bool
}

const defaultProps = {
    ...SectionProps.defaults,
    split: false
}

const Cta = () => {

    return (
        <section className="subscribe-newsletter">
            <div className="download-the-app container">
                <div className="row">
                    <div className="col-md-6">
                        <img src={require('../../assets/images/grabbi-cta-app.png')} alt="Grabbi App"/>
                    </div>
                    <div className="col-md-6 align-self-center">
                        <div>
                            <h3>{translate("get-the-app.h3")}</h3>
                            <h1 style={{color: "#fff"}}>
                                <strong>
                                    {translate("get-the-app.h1.1")}
                                    <br/>
                                    <span className="grabbi-color-2">
                                        {translate("get-the-app.h1.2")}
                                    </span>
                                </strong>
                            </h1>
                            <p className="mb-4 mt-3">{translate("get-the-app.p")}</p>
                            <div className="align-content-end">
                                <AppStoreIcons />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;