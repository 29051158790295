import {LOCALES} from "../locales";

export default {
    [LOCALES.DEUTSCH]: {
        "nav.publish": "Für Einzelhändler:innen",
        "nav.getapp": "Hol Dir die App",

        "hero.title1.label": "Dein Berliner Einzelhandel verbunden",
        "hero.title2.label": "in einer App.",
        "hero.text1.label": "Entdecke neue Läden in Minuten, finde einzigartige Produkte und lass dir deine Bestellung am selben Tag nach Hause liefern.",
        "hero.cta.label": "Downloaden und loslegen",

        "whatwedo.h1-1": "Dein digitaler Marktplatz ",
        "whatwedo.h1-2": "für lokale Shops.",
        "whatwedo.h4": "Was wir tun",
        "whatwedo.threecolumns.h4": "Unsere Werteversprechen",
        "whatwedo.threecolumns.h1": "Wir helfen dabei, das Stadtbild nachhaltig zu prägen",

        "whatwedo.column1.title": "CO2-neutrale Lieferung",
        "whatwedo.column1.p": "Mit einer ausschließliche Zustellung durch Fahrrädern oder alternativer  Elektromo-bilität, garantieren wir bei Grabbi einen klimaneutralen Transport bzw. Versand aller Bestellungen.",
        "whatwedo.column2.title": "Aufbau einer Community",
        "whatwedo.column2.p": "Uns ist wichtig, das wir den urbanen Raum nachhaltig mitgestalten und dazu beitragen, ihn langfristig zu erhalten. Wir wollen den lokalen Einzelhandel unterstützen und ihn konkurrenzfähiger machen.",
        "whatwedo.column3.title": "Keine zusätzliche Verpackung",
        "whatwedo.column3.p": "Unnötiger Müll bei der Aus-lieferung? Muss nicht sein! Wenn du bei Grabbi bestellst, erhältst du deine Produkte ohne zusätzliche Verpackung.",
        "whatWeDo.paragraph": "Grabbi ist viel mehr als ein herkömmlicher Lieferdienst. Wir verleihen dem lokalen Einzelhandel ein digitales Gesicht. Stöbere bequem von zu Hause aus durch Läden aus Deinem sowie vielen anderen Kiezen, unterstütze gleichzeitig Einzelhändler:innen in ganz Berlin und erhalte die Bestellung am selben Tag.",

        "vendorsBanner.h2": "Du hast einen eigenen Shop?",
        "vendorsBanner.button": "Jetzt registrieren",


        "withoutGrabbi.h4": "Warum Grabbi",
        "withoutGrabbi.p": "Das Einzugsgebiet zählt im stationären Handel zu den entscheidenden Erfolgsfaktoren. Je größer dieses Gebiet ist, umso höher ist die Anzahl an potenziellen Kund:innen. Das Problem – ist die Distanz zwischen dem Shop und dem eigenen Zuhause jedoch zu groß, entscheiden sich Kund:innen oft für eine näher gelegene oder digitale Alternative.",
        "withGrabbi.p": "Grabbi löst dieses Problem und erweitert Deinen Kund:innenradius um ein Vielfaches. Mit unserem Service geben Wir Dir die Möglichkeit, deutlich mehr Menschen in der ganzen Stadt zu erreichen. Gemeinsam erhöhen wir nicht nur die Zahl an potentielle Kund:innen, sondern machen diese gleichzeitig noch zu tatsächlichen, steigern deine Absatzzahlen und letzlich deine Umsätze.",

        "vendors.column1.title": "Erreiche neue, weit entfernte Kieze",
        "vendors.column2.title": "Alle Bestellungen mit einer Abholung",
        "vendors.column3.title": "Keine Fixkosten, kein Risiko",

        "vendors.column1.p": "Löse geographische Grenzen auf, erweitere deine Reichweite um ein Vielfaches und gewinne so neue Kund:innen",
        "vendors.column2.p": "Wir kommen nur einmal am Tag zur gleichen Zeit vorbei und holen alle Bestellungen auf einmal ab. So kannst du deinen Tag entsprechend planen.",
        "vendors.column3.p": "Unser Model funktioniert auf Provisionsbasis. Du hast keine Fixkosten und kannst jederzeit diie Zusammenarbeit mit uns beenden.",

        "hero.listTitle1": "LOKALE PRODUKTE",
        "hero.listSubTitle1": "Unterstütze Deinen Kiez und den lokalen Einzelhandel.",
        "hero.listTitle2": "SAME-DAY LIEFERUNG",
        "hero.listSubTitle2": "Erhalte Produkte die Du liebst, noch am selben Tag.",
        "hero.listTitle3": "NACH-HALTIGKEIT",
        "hero.listSubTitle3": "CO²-neutrale Lieferung. Ohne zusätzliche Verpackung.",

        "hero.appShowcase.title": "DIREKTE LIEFERUNG VON GESCHÄFTEN IN DEINER NÄHE",
        "hero.appShowcase.list1": "Lass dir deine Lieblingsprodukte innerhalb eines Tages nach Hause liefern",
        "hero.appShowcase.list2": "Entdecke neue Geschäfte und lass dich von einzigartigen Produkten inspirieren",
        "hero.appShowcase.list3": "In unseren Lieferungen ist keine zusätzliche Verpackung enthalten",
        "hero.appShowcase.list4": "Wir unterstützen lokale Geschäfte, indem wir ihre Produkte auf unserer Plattform promoten",
        "hero.bssText": "Grabbi wird vom 01.12.2021 bis 01.12.2022 vom InnoTechHub der Hochschule für Technik und Wirtschaft gefördert.",

        "who-we-are.h4": "UNSERE PHILOSOPHIE",
        "who-we-are.h1.2a": "Den ",
        "who-we-are.h1.2b": "Charme ",
        "who-we-are.h1.2c": "von ",
        "who-we-are.h1.2d": "Berlin erhalten",

        "who-we-are.h1.1a": "Lokale Shops ",
        "who-we-are.h1.1b": "mit ",
        "who-we-are.h1.1c": "Menschen verbinden",

        "who-we-are.h1.1a-mobile": "Lokale Shops mit Menschen verbinden",

        "who-we-are.paragraph": "Bei Grabbi sind wir davon überzeugt, dass die Innenstädte auf den lokalen Einzelhandel angewiesen sind. Er prägt die Wahrnehmung des urbanen Raums, ist ein Stück der kulturellen Szene und schafft Verbindung. Der Einzelhandel erzählt Geschichten von Menschen, die jeden Laden einzigartig werden lassen und das Stadtbild entscheidend mitgestalten. ",
        "who-we-are.paragraph2": "Wir verstehen uns nicht als Konkurrenz zum Einzelhandel, sondern als Bindeglied für Menschen. Durch unsere Idee wollen wir Zusammenhalt schaffen und der Distanzierung durch den klassischen Onlinehandel entgegenwirken. Unser Ziel ist es, Einzelhändler:innen speziell in Zeiten von Krisen zu unterstützen und gemeinsam mit ihnen Berlins Charme und Charakter zu erhalten.",

        "featureTiles.title": "Wir verbinden Berlin",
        "featureTiles.subTitle": "Ein digitales Einkaufszentrum in deiner Nähe mit Lieferung am selben Tag",
        "featureTiles.button": "Alle Marken anzeigen",

        "operations.subtitle": "Unser Konzept",
        "operations.title": "Von lokalen Geschäften, direkt zu dir nach Hause",
        "operations.browse": "1. Suche",
        "operations.browse.text": "Entdecke und finde Produkte von Geschäften in deiner Nähe",
        "operations.order": "2. Bestelle",
        "operations.order.text": "Problemlose Bestellung und sichere Bezahlung über dein Smartphone",
        "operations.receive": "3. Erhalte",
        "operations.receive.text": "Du erhältst deine Bestellung noch am selben Tag",


        "testimonials.title": "Personen, die unsere Vision unterstützen",
        "testimonials.subTitle": "Lies über die Erfahrungen unserer Kunden mit unseren Services",
        "testimonials.text1": "— Ich komme aus Mexiko und koche gerne internationale Gerichte. Grabbi hat mir geholfen, neue Geschäfte zu entdecken, welche Zutaten anbieten, die ich brauche. Auch wenn ich nach der Arbeit keine Zeit habe persönlich einzukaufen, kann ich die Ware noch am selben Tag vor die Haustür geliefert bekommen.",
        "testimonials.position1": "Entwickler",
        "testimonials.text2": "— Seit ich bei Grabbi bin, habe ich nicht nur neue Kunden gewonnen - es ist mir auch gelungen, meinen Service mit Lieferung am selben Tag und ohne zusätzliche Kosten zu verbessern. Außerdem hat sich das Grabbi Team um den gesamten Onboarding-Prozess gekümmert.",
        "testimonials.position2": "Beisitzer",
        "testimonials.text3": "— Durch Grabbi kann ich meine lokale Gemeinschaft unterstützen und einen Beitrag zu einem nachhaltigeren Einkauf im E-Commerce leisten. Zusätzlich konnte ich auch viele einzigartige Produkte aus meinem Kiez entdecken.",
        "testimonials.position3": "Consultant",

        "how-it-works.h4": "SO FUNKTIONIERT’S",
        "how-it-works.h1": "Bestellen in nur drei Schritten",

        "how-it-works.item.title1": "SUCHEN",
        "how-it-works.item.text1": "Stöbere durch Läden in Berlin und Deinem Kiez.",

        "how-it-works.item.title2": "BESTELLEN",
        "how-it-works.item.text2": "Wähle Deine Lieblingsprodukte und klicke auf bestellen.",

        "how-it-works.item.title3": "ERHALTEN",
        "how-it-works.item.text3": "Lieferung am gleichen Tag – zu Dir nach Hause!",


        "get-the-app.h3": "Hol dir jetzt die App",
        "get-the-app.h1.1": "Entdecke deinen Kiez.",
        "get-the-app.h1.2": "Kaufe lokal.",
        "get-the-app.h2.1": "Entdecke deinen Kiez.",
        "get-the-app.h2.2": "Kaufe lokal.",
        // "get-the-app.p": "Wir sind schon jetzt in vielen Teilen Berlins live. Du kannst bei uns in Mitte, P.Berg, Friedrichshain, Moabit und Kreuzberg bestellen.",
        "get-the-app.p": "Berliner:innen aufgepasst! Wir sind schon jetzt in vielen Teilen Deiner Stadt live und liefern nach Mitte, Moabit, Prenzlauer Berg, Friedrichshain und Kreuzberg.",

        "cta.title": "Möchtest du unsere Updates erhalten?",
        "cta.subTitle": "JETZT ABONNIEREN",
        "cta.textInput": "Deine E-Mail",
        "cta.subscribeButton": "Abonnieren",

        "footer.list1": "Kontakt",
        "footer.list2": "Über uns",
        "footer.list3": "FAQ",
        "footer.list4": "Kundenservice",
        "footer.list2-1": "Datenschutz",
        "footer.list2-2": "Nutzungsbedingungen",
        "footer.list2-3": "Werde unser Partner",
        "footer.list2-4": "Impressum",
        "footer.copyright": "Entwickelt von Eric Lehmann",



        "vendor.header.h1": "Erreiche neue Kund:innen, vergrößere Deine Reichweite und erhalte mehr Bestellungen.",
        "vendor.header.button": "Jetzt registrieren",
        "vendor.why-us.h4.1": "WARUM GRABBI",
        "vendor.why-us.p.1": "Das Einzugsgebiet zählt im stationären Handel zu den entscheidenden Erfolgsfaktoren. Ist die Distanz zwischen dem Shop und dem eigenen Zuhause jedoch zu groß, entscheiden sich viele für eine näher gelegene oder digitale Alternative.",
        "vendor.why-us.p.2": "Grabbi hilft Dir dabei, Deinen Kund:innenradius um ein Vielfaches zu erweitern. Mit unserem Service hast Du die Möglichkeit, deutlich mehr Menschen in ganz Berlin zu erreichen, Deine Zahl an Kund:innen zu erhöhen und Deine Umsätze zu steigern.",
        "vendor.why-us.column1.h1": "Vergrößere Deine Reichweite",
        "vendor.why-us.column1.p": "Lass Deine Produkte in neue Teile der Stadt liefern, vergrößere Deine Reichweite und gewinne so viele neue Kund:innen für Dein Unternehmen.",
        "vendor.why-us.column2.h1": "Alle Bestellungen mit einer Abholung",
        "vendor.why-us.column2.p": "Die Bestellungen werden nur einmal am Tag und während eines festgelegten Zeitfenster abgeholt. Dadurch kannst Du entsprechend planen und Dein zusätzliche Aufwand bleibt minimal.",
        "vendor.why-us.column3.h1": "Keine Fixkosten, kein Risiko",
        "vendor.why-us.column3.p": "Grabbi funktioniert auf Provi-sionsbasis. Es entstehen keine fixen Kosten und Du kannst die Zusammenarbeit mit uns jederzeit beenden.",
        "vendor.why-us.how-it-works.h4": "So funktioniert’s",
        "vendor.why-us.how-it-works.p": "Es sind lediglich drei Schritte, bis Deine Kund:innen ihre Bestellungen in den Händen halten. Alles was Du dafür benötigst, sind ein eigener Laden bzw. ein erreichbares Lager sowie verfügbare digitale Daten zu Deinen Produkten (u. a. Bilder, Beschreibung, Preis).",
        "vendor.why-us.how-it-works.card1.title": "Mitteilung",
        "vendor.why-us.how-it-works.card1.p": "Um 16 Uhr erhältst Du die genauen Informationen zu den Bestellungen.",
        "vendor.why-us.how-it-works.card2.title": "Vorbereitung",
        "vendor.why-us.how-it-works.card2.p": "Du bereitest die Produkte entsprechend der Benachrichtung vor.",
        "vendor.why-us.how-it-works.card3.title": "Abholung",
        "vendor.why-us.how-it-works.card3.p": "Die Bestellung wird zwischen 16 – 18 Uhr bei Dir im Laden abgeholt und von uns zugestellt.",
        "vendor.why-us.contact-from.h4": "Werde zum grabbit",
        "vendor.why-us.contact-from.h1.1": "Du denkst, dass Grabbi die richtige Wahl für Dich und Deinen Laden ist?",
        "vendor.why-us.contact-from.h1.2": " Dann melde Dich bei uns!",
        "vendor.why-us.contact-from.field1": "Dein Name",
        "vendor.why-us.contact-from.field2": "Name Deines Ladens",
        "vendor.why-us.contact-from.field3": "Telefonnummer",
        "vendor.why-us.contact-from.field4": "E-Mail Adresse",
        "vendor.why-us.contact-from.sendButton": "Registrierung senden",
    }
}