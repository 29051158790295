import React, {useLayoutEffect} from 'react';
import {Route, BrowserRouter, Routes, useLocation} from "react-router-dom";

// Views 
import Home from './views/Home';
import TermsAndConditions from "./views/TermsAndConditions";
import Privacy from "./views/Privacy";
import BecomeAPartner from "./views/BecomeAPartner";
import Impressum from "./views/Impressum";
import AccountDeletion from "./views/AccountDeletion";

const App = () => {

    const Wrapper = ({children}) => {
        const location = useLocation();
        useLayoutEffect(() => {
            document.documentElement.scrollTo(0, 0);
        }, [location.pathname]);
        return children
    }

    return (
        <BrowserRouter>
            <Wrapper>
                <Routes>
                    <Route exact path="/" element={<Home/>} />
                    <Route path="/terms-conditions" element={<TermsAndConditions/>}/>
                    <Route path="/privacy" element={<Privacy/>}/>
                    <Route path="/become-a-partner" element={<BecomeAPartner/>} />
                    <Route path="/impressum" element={<Impressum/>} />
                    <Route path="/delete-account" element={<AccountDeletion/>} />
                </Routes>
            </Wrapper>
        </BrowserRouter>
    )
}

export default App;