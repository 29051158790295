import React from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import translate from "../../../i18n/translate";

const FooterNav = ({
                       className,
                       ...props
                   }) => {

    const classes = classNames(
        className
    );

    return (
        <nav className="footer-navigation">
            <div className="row">
                <ul className="list-reset col-6">
                    <li>
                        <Link to="/impressum">
                            {translate('footer.list2-4')}
                        </Link>
                    </li>
                    <li>
                        <Link to="/privacy">
                            {translate('footer.list2-1')}
                        </Link>
                    </li>

                    <li>
                        <Link to="/terms-conditions">
                            {translate('footer.list2-2')}
                        </Link>
                        {/*<a href="/terms-conditions" rel="noreferrer">{translate('footer.list2-2')}</a>*/}
                    </li>
                    {/*<li>*/}
                    {/*    <Link to="/become-a-partner">*/}
                    {/*        {translate('footer.list2-3')}*/}
                    {/*    </Link>*/}
                    {/*    /!*<a href="/terms-conditions" rel="noreferrer">{translate('footer.list2-2')}</a>*!/*/}
                    {/*</li>*/}
                </ul>

                <ul className="list-reset col-6">
                    <li>
                        <a href="mailto: grabbi.app@gmail.com" rel="noreferrer">{translate('footer.list1')}</a>
                    </li>
                    <li>
                        <a href="#who-we-are" rel="noreferrer">{translate('footer.list2')}</a>
                    </li>
                    {/*<li>*/}
                    {/*    <a href="#0" rel="noreferrer">{translate('footer.list3')}</a>*/}
                    {/*</li>*/}
                    <li>
                        <Link to="/delete-account">
                            Konto löschen
                        </Link>
                        {/*<a href="mailto: info@grabbi.de" rel="noreferrer">{translate('footer.list4')}</a>*/}
                    </li>
                </ul>
            </div>

        </nav>
    );
}

export default FooterNav;