import getAppGooglePlay from "../../assets/images/getapp_google_play.png";
import getAppIos from "../../assets/images/getapp_ios.png";
import React from "react";
import {clickButton} from "../../helpers/analyticsEvents";

export function AppStoreIcons() {
    return(
        <div className="d-block pt-3 position-relative ">
            <div className="container mobile-store-icons m-0 d-flex justify-content-center d-lg-block justify-content-lg-start">
                <div className="row">
                    <div className="col-sm-12 d-flex flex-row gap-3">
                        <a className="mr-4" href="https://play.google.com/store/apps/details?id=com.grabbi.grabbiapp" onClick={() => clickButton('get_app_android', document.title)}>
                            <img className="mb-2" src={getAppGooglePlay}
                                 alt={"Grabbi app Play Store"}/>
                        </a>

                        <a href="https://apps.apple.com/de/app/grabbi/id1584867620" onClick={() => clickButton('get_app_ios', document.title)}>
                            <img className="mb-2" src={getAppIos} alt={"Grabbi app App Store"}/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
