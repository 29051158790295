import React, {useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import axios from "axios";

const ContactFormVendors = ({modalVisible, toggleModal}) => {

    const [name, setName] = useState("");
    const [shop, setShop] = useState("");
    const [tel, setTel] = useState("");
    const [email, setEmail] = useState("");

    const [submitting, setSubmitting] = useState(false);

    function handleSubmit() {

        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let regTel = /^[0-9]*$/;


        if (!name || !shop || !tel || !email) {
            alert("Bitte gib alle die Information ein")
        } else if (!regTel.test(tel)) {
            alert("Bitte gib eine gültige Telefonnummer")
        } else if (!regEmail.test(email)) {
            alert("Bitte gib eine gültige E-Mail Adresse")
        } else {
            setSubmitting(true);

            const bodyFormData = new FormData();

            bodyFormData.append('person-name', name)
            bodyFormData.append('shop', shop)
            bodyFormData.append('tel', tel)
            bodyFormData.append('email', email)

            axios({
                method: "post",
                url: "https://grabbi.de/wp-json/contact-form-7/v1/contact-forms/19096/feedback",
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data" },
            })
                .then(function (response) {
                    //handle success
                    console.log(response);
                    setSubmitting(false);
                    toggleModal();
                    alert(response.data.message);

                })
                .catch(function (response) {
                    //handle error
                    console.log(response);
                    setSubmitting(false);
                    toggleModal();
                    alert("Es gab ein Fehler");
                });
        }
    }


    function renderForm() {
        return (
            <div className="container">
                <label className="mb-2 row">
                    <span className="fw-bold row mb-2">Dein Name</span>
                    <input
                        className="col form-control"
                        type="text"
                        value={name}
                        required
                        onChange={(e) => setName(e.target.value)}
                    />
                </label>
                <label className="mb-2 row">
                    <span className="fw-bold row mb-2">Name Deines Ladens</span>
                    <input
                        className="col form-control"
                        type="text"
                        value={shop}
                        required
                        onChange={(e) => setShop(e.target.value)}
                    />
                </label>
                <label className="mb-2 row">
                    <span className="fw-bold row mb-2">Telefonnummer</span>
                    <input className="col form-control"
                           type="tel"
                           value={tel}
                           required
                           onChange={(e) => setTel(e.target.value)}
                    />
                </label>
                <label className="mb-2 row">
                    <span className="fw-bold row mb-2">E-Mail Adresse</span>
                    <input className="col form-control"
                           type="email"
                           value={email}
                           required
                           onChange={(e) => setEmail(e.target.value)}
                    />
                </label>
            </div>
        )
    }

    return (

        <Modal show={modalVisible} onHide={toggleModal}>
            <Modal.Header closeButton>
                <Modal.Title>Melde Dich bei uns!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {renderForm()}
            </Modal.Body>
            <Modal.Footer>
                {/*<Button variant="secondary" onClick={toggleModal}>*/}
                {/*    Close*/}
                {/*</Button>*/}
                <Button
                    variant={!submitting ? "secondary" :"primary"}
                    style={
                        !submitting ? {backgroundColor: "#42125C"} : {backgroundColor: "grey"}
                    }
                    disabled={submitting ? true : false}
                    onClick={handleSubmit}
                >
                    Registrierung senden
                </Button>
            </Modal.Footer>
        </Modal>

    );
};

export default ContactFormVendors;