import React, {useState} from 'react';
import translate from "../../i18n/translate";

import sellWithGrabbi from '../../assets/images/SellWithGrabbi.png';
import ContactFormVendors from "./ContactFormVendors";
import {useNavigate} from "react-router-dom";


const CtaVendorsBanner = ({
                              className,
                              topOuterDivider,
                              bottomOuterDivider,
                              topDivider,
                              bottomDivider,
                              hasBgColor,
                              invertColor,
                              invertMobile,
                              invertDesktop,
                              alignTop,
                              imageFill,
                              language,
                              ...props
                          }) => {


    const [modalVisible, setModalVisible] = useState(false);


    const navigate = useNavigate();

    function toggleModal() {
        navigate("/become-a-partner");
        // setModalVisible(!modalVisible)
    }

    return (
        <section id="vendors-banner">
            <div className="container">
                <div className="row flex-row">
                    <div className="col-lg-7 offset-lg-1 flex-lg-row align-items-start align-self-center text-center text-lg-start">
                        <div className="row">
                            <img src={sellWithGrabbi} alt="Sell with Grabbi" className="d-block d-lg-none m-auto mb-3"/>
                        </div>
                        <div className="row mb-2">
                            <h2>
                                {translate('vendorsBanner.h2')}
                            </h2>
                        </div>
                        <div className="row text-start">
                            <button onClick={toggleModal}>{translate('vendorsBanner.button')}</button>
                        </div>
                    </div>
                    <div className="col-4">
                        <img src={sellWithGrabbi} alt="Sell with Grabbi" className="d-none d-lg-block"/>
                    </div>
                </div>
            </div>


            <ContactFormVendors modalVisible={modalVisible} toggleModal={toggleModal}/>

        </section>
    );
};

export default CtaVendorsBanner;
