import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import LayoutPages from "../layouts/LayoutPages";
import {LOCALES} from "../i18n";

const Impressum = () => {

    useEffect(() => {
        document.title = "Account deletion"
    }, []);

    const [locale, setLocale] = useState(LOCALES.DEUTSCH);

    function changeLanguage(lang) {
        setLocale(lang)
    }

    const location = useLocation();

    return (
        <LayoutPages locale={locale} language={changeLanguage}>
            <section id="delete-account">
                <div className="container mt-5">
                    <h2 className="text-black mb-3">Account deletion</h2>
                    {
                        locale === "en-US" ? (
                            <p>
                                To delete your user account on Grabbi, follow these steps. Firstly, ensure that you are logged into your Grabbi account. Once logged in, navigate to the "Profile" section, usually located in the top-right corner or within the account settings menu. Scroll down to the bottom of the profile page until you find the "Delete Account" button. Click on this button, and the platform may prompt you to confirm your decision or provide additional information for feedback. Keep in mind that deleting your account will permanently remove your profile and associated data from Grabbi, and this action is irreversible. Make sure you are certain about your decision before proceeding.
                            </p>
                        ) : locale === "de-de" && (
                            <p>
                                Um Ihr Benutzerkonto bei Grabbi zu löschen, befolgen Sie diese Schritte. Stellen Sie zunächst sicher, dass Sie in Ihrem Grabbi-Konto angemeldet sind. Nach dem Einloggen gehen Sie zum Abschnitt "Profil", der normalerweise oben rechts oder im Menü der Kontoeinstellungen zu finden ist. Scrollen Sie bis zum Ende der Profilseite, wo Sie den Button "Konto löschen" finden. Klicken Sie auf diesen Button, und die Plattform wird Sie möglicherweise auffordern, Ihre Entscheidung zu bestätigen oder zusätzliche Informationen für Feedback bereitzustellen. Beachten Sie, dass das Löschen Ihres Kontos Ihr Profil und alle damit verbundenen Daten dauerhaft von Grabbi entfernt, und dieser Vorgang ist nicht rückgängig zu machen. Stellen Sie sicher, dass Sie sich über Ihre Entscheidung im Klaren sind, bevor Sie fortfahren.
                            </p>
                        )
                    }

                </div>

            </section>

        </LayoutPages>
    );
};

export default Impressum;
