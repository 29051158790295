import React from 'react';
import classNames from 'classnames';
import {SectionTilesProps} from '../../utils/SectionProps';
import translate from "../../i18n/translate";

const propTypes = {
    ...SectionTilesProps.types
}

const defaultProps = {
    ...SectionTilesProps.defaults
}
const HowItWorks = ({
                           className,
                           location,
                           language,
                           topOuterDivider,
                           bottomOuterDivider,
                           topDivider,
                           bottomDivider,
                           hasBgColor,
                           invertColor,
                           pushLeft,
                           ...props
                       }) => {

    const outerClasses = classNames(
        'features-tiles section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    return (
        <section
            {...props}
            className={outerClasses}
            id="how-it-works"
        >
            <div className="mx-lg-5 reveal-from-bottom text-center text-md-start pb-5 pt-3">
                <div className="container py-5">
                    <div className="row my-4 mb-5">
                        <div className="col px-lg-5">
                            <h4 style={{color: "#42125C"}}>{translate("how-it-works.h4")}</h4>
                            <h1 className="">{translate("how-it-works.h1")}</h1>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-xl-4 col-sm-6 col-12 mb-5 mb-xl-0">
                            <div id="custom-card" className="container first">
                                <div className="card">
                                    <div className="imgBx">
                                        <img src={require('../../assets/images/how-it-works-suche.png')}/>
                                    </div>
                                    <div className="contentBx">
                                        <h2>{translate("how-it-works.item.title1")}</h2>
                                        <p>{translate("how-it-works.item.text1")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-sm-6 col-12 mb-5 mb-xl-0">
                            <div id="custom-card" className="container second">
                                <div className="card">
                                    <div className="imgBx">
                                        <img src={require('../../assets/images/how-it-works-bestelle.png')}/>
                                    </div>
                                    <div className="contentBx">
                                        <h2>{translate("how-it-works.item.title2")}</h2>
                                        <p>{translate("how-it-works.item.text2")}</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-sm-6 col-12 mb-5 mb-xl-0">
                            <div id="custom-card" className="container third">
                                <div className="card">
                                    <div className="imgBx">
                                        <img src={require('../../assets/images/how-it-works-erhalten.png')}/>
                                    </div>
                                    <div className="contentBx">
                                        <h2>{translate("how-it-works.item.title3")}</h2>
                                        <p>{translate("how-it-works.item.text3")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </section>
    );
}

HowItWorks.propTypes = propTypes;
HowItWorks.defaultProps = defaultProps;

export default HowItWorks;