import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Logo from './partials/Logo';
import FooterNav from './partials/FooterNav';
import FooterSocial from './partials/FooterSocial';
import translate from "../../i18n/translate";

import grabbiLogo from '../../assets/images/grabbi_logo.png';
import esfLogo from '../../assets/images/BSS/200525_esf-berlin_final.jpeg';
import euSozialFonds from '../../assets/images/BSS/eu-sozialfonds.jpeg';
import senatLogo from '../../assets/images/BSS/sen_web_vertikal_rgb_.png';
import htwLogo from '../../assets/images/BSS/S04_HTW_Berlin_Logo_pos_FARBIG_RGB.jpeg'
import MailchimpSubscribe from "react-mailchimp-subscribe";


const propTypes = {
    topOuterDivider: PropTypes.bool,
    topDivider: PropTypes.bool
}

const defaultProps = {
    topOuterDivider: false,
    topDivider: false
}

const Footer = ({
                    className,
                    language,
                    topOuterDivider,
                    topDivider,
                    ...props
                }) => {

    const classes = classNames(
        'site-footer center-content-mobile',
        topOuterDivider && 'has-top-divider',
        className
    );

    const url = "https://grabbi.us6.list-manage.com/subscribe/post?u=1f0fc8b6de0cf7449224bba1b&amp;id=eff56f20d7";

    const SimpleForm = ({status, message, onValidated}) => {
        let email;
        const submit = () =>
            email &&
            email.value.indexOf("@") > -1 &&
            onValidated({
                EMAIL: email.value,
            });

        return (
            <div className="row">
                <div style={{
                    background: "transparent",
                    borderRadius: 2,
                    paddingVertical: 10,
                    paddingHorizontal: 35
                }}>
                    <div className="subscribe-newsletter-input">
                        <input
                            style={{
                                fontSize: 16,
                                fontFamily: "Quicksand, sans-serif",
                                padding: 15,
                                boxShadow: "0px 5px 15px 0px rgba(0,0,0,0.35)",
                                width: "100%",
                                maxWidth: "400px"
                            }}
                            ref={node => (email = node)}
                            type="email"
                            placeholder="E-Mail"
                        />
                        <button style={{
                            fontSize: 16,
                            padding: 5,
                            backgroundColor: "#42125C",
                            boxShadow: "0px 5px 15px 0px rgba(0,0,0,0.35)",
                        }} onClick={submit}>
                            {translate('cta.subscribeButton')}
                        </button>
                    </div>
                </div>
                <div className="subscribe-newsletter-messages">
                    {status === "sending" && <div style={{color: "#42125C"}}>Registering...</div>}
                    {status === "error" && (
                        <div
                            className="MC_message_error"
                            dangerouslySetInnerHTML={{__html: message}}
                        />
                    )}
                    {status === "success" && (
                        <div
                            style={{color: "green"}}
                            dangerouslySetInnerHTML={{__html: message}}
                        />
                    )}
                </div>
            </div>


        );
    };

    return (
        <footer
            {...props}
            className={classes}
        >
            <div className="container">
                <div className='py-4 px-2 px-sm-5'>
                    <div className="container">


                        <div className="row">

                            <div className="col-12 col-lg-2">
                                <div className="footer-top text-center text-xxs">
                                    <img src={grabbiLogo} alt={"Grabbi logo"}
                                         style={{maxHeight: "15vh", margin: 'auto'}}/>
                                    <FooterSocial/>
                                </div>
                            </div>

                            <div className="col-12 col-lg-5 d-flex justify-content-center align-items-center">
                                <div className="justify-content-center text-xxs invert-order-desktop">
                                    <FooterNav language={language}/>
                                </div>
                            </div>

                            <div className="col-12 col-lg-5">
                                <div className="signup-container">
                                    <div className="col text-center text-lg-start mb-3">
                                        {/*<h3>{translate('cta.title')}<br/>{translate('cta.subTitle')}</h3>*/}
                                        <h4 style={{color: "#fff"}}>{translate('cta.subTitle')}</h4>
                                    </div>

                                    <MailchimpSubscribe
                                        url={url}
                                        render={({subscribe, status, message}) => (
                                            <SimpleForm
                                                status={status}
                                                message={message}
                                                onValidated={formData => subscribe(formData)}
                                            />
                                        )}
                                    />

                                </div>
                            </div>
                        </div>

                        <div className="row my-3 m-auto bss-logos" style={{maxWidth: 800}}>
                            <div style={{flex: "1 1 150px"}}>
                                <img src={esfLogo} className="footer-images-bss"/>
                            </div>
                            <div style={{flex: "1 1 150px"}}>
                                <img src={euSozialFonds} className="footer-images-bss"/>
                            </div>
                            <div style={{flex: "1 1 150px"}}>
                                <img src={senatLogo} className="footer-images-bss"/>
                            </div>
                            <div style={{flex: "1 1 150px"}}>
                                <img src={htwLogo} className="footer-images-bss"/>
                            </div>
                        </div>

                        <div className="row">
                            <div className="w-100 text-center">
                                <p className="mb-0 text-xxs">{translate("footer.copyright")}</p>
                                <p className="text-xxs">All right reserved</p>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </footer>
    );
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;