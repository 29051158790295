import React from 'react';
import classNames from 'classnames';
import {SectionSplitProps} from '../../utils/SectionProps';
import Parallax from "react-rellax";
import translate from "../../i18n/translate";
import grabbiIntro from "../../assets/images/grabbi_intro_2.png";

const propTypes = {
    ...SectionSplitProps.types
}

const defaultProps = {
    ...SectionSplitProps.defaults
}

const WhatWeDo = ({
                      className,
                      topOuterDivider,
                      bottomOuterDivider,
                      topDivider,
                      bottomDivider,
                      hasBgColor,
                      invertColor,
                      invertMobile,
                      invertDesktop,
                      alignTop,
                      imageFill,
                      language,
                      ...props
                  }) => {

    const innerClasses = classNames(
        'features-split-inner section-inner',
        topDivider,
        bottomDivider
    );

    return (
        <section id="what-we-do">
            {/*<Parallax speed={1.5}>*/}
            {/*    <img className="img-bg d-none d-xl-block" src={require("../../assets/images/bg_overlay_1.png")}/>*/}
            {/*</Parallax>*/}

            <div className="container-sm full-screen-height">
                <div className={innerClasses} style={{zIndex: 2}}>
                    <div className="hero-figure reveal-from-bottom illustration-element-01 app-showcase"
                         data-reveal-value="20px" data-reveal-delay="800">

                        <div className="container mt-md-4">
                            <div className="row">

                                <div className="col-xl-5 col-12 app-showcase-image d-flex pb-xl-0 pb-4 d-none d-xl-block">
                                    <img src={grabbiIntro} alt={"Grabbi app"} className="m-auto align-self-center p-lg-2"/>
                                </div>

                                <div
                                    className="ps-lg-5 col-xl-7 text-lg-start align-self-center text-center pe-lg-4 d-flex"
                                    style={{maxWidth: 940}}>

                                    <div className="row m-auto justify-content-start align-self-center mt-4 mt-lg-auto ps-lg-3"
                                         style={{maxWidth: 1000}}>
                                        <h4 className="mb-4 text-uppercase" style={{color: "#42125C"}}>{translate("whatwedo.h4")}</h4>
                                        <h1 className="mb-4">
                                            {translate("whatwedo.h1-1")}
                                            <br/>
                                            <span className="mb-5 grabbi-color-1">{translate("whatwedo.h1-2")}</span>
                                        </h1>

                                        <img src={grabbiIntro} alt={"Grabbi app"}
                                             className="m-auto pb-5 pt-4 align-self-center p-xl-2 d-block d-xl-none"
                                             style={{maxHeight: 540, width: "auto"}}
                                        />

                                        <div className="col-md-12 what-we-do-bullets">
                                            {/*<div className="d-flex flex-row align-items-center mb-4">*/}
                                            {/*    <img*/}
                                            {/*        alt="Local shops"*/}
                                            {/*        src={require('../../assets/images/ICON_store.png')}*/}
                                            {/*        style={{height: 60, width: 60, marginRight: 18}}*/}
                                            {/*    />*/}
                                            {/*    <span className="text-start">{translate('hero.listSubTitle1')}</span>*/}
                                            {/*</div>*/}
                                            {/*<div className="d-flex flex-row align-items-center mb-4">*/}
                                            {/*    <img*/}
                                            {/*        alt="Local shops"*/}
                                            {/*        src={require('../../assets/images/ICON_delivery-bike.png')}*/}
                                            {/*        style={{height: 60, width: 60, marginRight: 18}}*/}
                                            {/*    />*/}
                                            {/*    <span className="text-start">{translate('hero.listSubTitle2')}</span>*/}
                                            {/*</div>*/}
                                            {/*<div className="d-flex flex-row align-items-center mb-5">*/}
                                            {/*    <img*/}
                                            {/*        alt="Local shops"*/}
                                            {/*        src={require('../../assets/images/ICON_plant.png')}*/}
                                            {/*        style={{height: 60, width: 60, marginRight: 18}}*/}
                                            {/*    />*/}
                                            {/*    <span className="text-start">{translate('hero.listSubTitle3')}</span>*/}
                                            {/*</div>*/}

                                            <div className="mb-4 text-center text-lg-start">
                                                <span className="text-start">{translate('whatWeDo.paragraph')}</span>
                                            </div>
                                            {/*<AppStoreIcons/>*/}
                                        </div>
                                    </div>

                                    {/*<div className="row m-auto mb-5 text-center flex-wrap justify-content-center" style={{maxWidth: 1000}}>*/}
                                    {/*    <div className="col-sm-6 mb-4">*/}
                                    {/*        <div className="card justify-content-center">*/}
                                    {/*            <img src={require('../../assets/images/ICON_store.png')}*/}
                                    {/*                 className="card__image"*/}
                                    {/*                 alt="Local shops"/>*/}
                                    {/*            <div className="card__overlay">*/}
                                    {/*                <div className="card__header">*/}
                                    {/*                    <svg className="card__arc" xmlns="http://www.w3.org/2000/svg">*/}
                                    {/*                        <path/>*/}
                                    {/*                    </svg>*/}
                                    {/*                    <div className="card__header-text">*/}
                                    {/*                        <h3 className="card__title">{translate('hero.listTitle1')}</h3>*/}
                                    {/*                    </div>*/}
                                    {/*                </div>*/}
                                    {/*                <p className="card__description">*/}
                                    {/*                    {translate('hero.listSubTitle1')}*/}
                                    {/*                </p>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="col-sm-6 mb-4">*/}
                                    {/*        <div className="card">*/}
                                    {/*            <img src={require('../../assets/images/ICON_delivery-bike.png')}*/}
                                    {/*                 className="card__image"*/}
                                    {/*                 alt="Same day delivery"/>*/}
                                    {/*            <div className="card__overlay">*/}
                                    {/*                <div className="card__header">*/}
                                    {/*                    <svg className="card__arc" xmlns="http://www.w3.org/2000/svg">*/}
                                    {/*                        <path/>*/}
                                    {/*                    </svg>*/}
                                    {/*                    <div className="card__header-text">*/}
                                    {/*                        <h3 className="card__title">{translate('hero.listTitle2')}</h3>*/}
                                    {/*                    </div>*/}
                                    {/*                </div>*/}
                                    {/*                <p className="card__description">*/}
                                    {/*                    {translate('hero.listSubTitle2')}*/}
                                    {/*                </p>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="col-sm-6 mb-4">*/}
                                    {/*        <div className="card">*/}
                                    {/*            <img src={require('../../assets/images/ICON_plant.png')}*/}
                                    {/*                 className="card__image"*/}
                                    {/*                 alt="Sustainable delivery"/>*/}
                                    {/*            <div className="card__overlay">*/}
                                    {/*                <div className="card__header">*/}
                                    {/*                    <svg className="card__arc" xmlns="http://www.w3.org/2000/svg">*/}
                                    {/*                        <path/>*/}
                                    {/*                    </svg>*/}
                                    {/*                    <div className="card__header-text">*/}
                                    {/*                        <h3 className="card__title">{translate('hero.listTitle3')}</h3>*/}
                                    {/*                    </div>*/}
                                    {/*                </div>*/}
                                    {/*                <p className="card__description">*/}
                                    {/*                    {translate('hero.listSubTitle3')}*/}
                                    {/*                </p>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                </div>
                            </div>

                        </div>
                    </div>

                    {/*THREE COLUMNS BENEFITS*/}

                    <div className="container my-5" style={{zIndex: 2, maxWidth: "1200px"}}>
                        <div className="row align-items-start">

                            <div className="ms-lg-4 mt-5 mb-3">
                                <h4 className="text-uppercase px-2 mb-4" style={{color: "#42125C"}}>
                                    {translate("whatwedo.threecolumns.h4")}
                                </h4>
                                <h1 className="mb-lg-4 px-2">
                                    {translate("whatwedo.threecolumns.h1")}
                                </h1>
                            </div>

                            <div className="col-lg-4 text-center">
                                <img className="img-columns" src={require("../../assets/images/Grabbi_express_delivery.png")}/>
                                <div className="txt-columns">
                                    <h4 className="mb-4">{translate('whatwedo.column1.title')}</h4>
                                    <p>{translate('whatwedo.column1.p')}</p>
                                </div>
                            </div>
                            <div className="col-lg-4 text-center">
                                <img className="img-columns" src={require("../../assets/images/Grabbi_social_engagement.png")}/>
                                <div className="txt-columns">
                                    <h4 className="px-2 mb-4">{translate('whatwedo.column2.title')}</h4>
                                    <p>{translate('whatwedo.column2.p')}</p>
                                </div>
                            </div>
                            <div className="col-lg-4 text-center">
                                <img className="img-columns" src={require("../../assets/images/Grabbi_nachhaltig.png")}/>
                                <div className="txt-columns">
                                    <h4 className="mb-4">{translate('whatwedo.column3.title')}</h4>
                                    <p>{translate('whatwedo.column3.p')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <div className="area">
                <ul className="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>
        </section>


        // <section
        //     {...props}
        //     className={outerClasses}
        // >
        //     <div className="container max-width-1200 full-screen-height justify-content-center">
        //         <div className={innerClasses}>
        //             {/*<SectionHeader data={sectionHeader} className="center-content"/>*/}
        //             <div className={splitClasses}>
        //
        //                 <div className="split-item how-it-works">
        //                     <div className="container" data-reveal-container=".split-item">
        //                         <div className="row align-items-center how-it-works-row-section">
        //                             <div className="col-md-6 d-md-none mb-5">
        //                                 <Image
        //                                     src={appShowcase2}
        //                                     alt="Grabbi app showcase"
        //                                     className="mx-auto"
        //                                 />
        //                             </div>
        //                             <div className="col-md-6">
        //                                 <div className="reveal-from-left" data-reveal-container=".split-item">
        //                                     <img
        //                                         className="image-how-it-works-cycle"
        //                                         src={require('../../assets/images/how_it_works_cycle.png')}
        //                                     />

        //                                 </div>
        //                             </div>
        //                             <div className="col-md-6 d-none d-md-block">
        //                                 <Image
        //                                     src={appShowcase2}
        //                                     alt="Grabbi app showcase"
        //                                     className="ms-auto"
        //                                 />
        //                             </div>
        //
        //                         </div>
        //
        //                     </div>
        //                 </div>
        //
        //             </div>
        //         </div>
        //     </div>
        // </section>
    );
}

WhatWeDo.propTypes = propTypes;
WhatWeDo.defaultProps = defaultProps;

export default WhatWeDo;