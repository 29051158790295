import React, {useEffect, useRef, useState} from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import {I18nProvider, LOCALES} from "../i18n";
import {useLocation} from "react-router-dom";
import ScrollReveal from "../utils/ScrollReveal";


const LayoutDefault = ({children, locale, language}) => {

    const childRef = useRef();
    let location = useLocation();

    useEffect(() => {
        document.body.classList.add('is-loaded')
        childRef.current.init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <>
            <main className="site-content">
                <ScrollReveal
                    ref={childRef}
                    children={() => (
                        <I18nProvider locale={locale}>
                            <Header
                                navPosition="right"
                                className="reveal-from-bottom"
                                language={language}
                            />
                            {children}
                            <Footer language={locale}/>
                        </I18nProvider>
                    )}/>
            </main>
        </>
    )

};

export default LayoutDefault;  