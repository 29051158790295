import React from 'react';
import PropTypes from 'prop-types';
import {SectionProps} from '../../utils/SectionProps';


const propTypes = {
    ...SectionProps.types,
    split: PropTypes.bool
}

const defaultProps = {
    ...SectionProps.defaults,
    split: false
}

const VendorsCall = () => {


    return (
        <section id="vendors-call">
            <div className="container">
                <div className="row">
                    <h1>TEST</h1>
                </div>
            </div>
        </section>
    );
}

VendorsCall.propTypes = propTypes;
VendorsCall.defaultProps = defaultProps;

export default VendorsCall;