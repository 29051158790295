import React, {useState} from 'react';
import classNames from 'classnames';
import {SectionProps} from '../../utils/SectionProps';

import appShowcase from '../../assets/images/app-showcase.png';
import getAppGooglePlay from '../../assets/images/getapp_google_play.png';
import getAppIos from '../../assets/images/getapp_ios.png';
import arrowImage from '../../assets/images/arrow_hero.png';
// import RotatingText from '../elements/RotatingText';
import translate from "../../i18n/translate";
import {clickButton} from "../../helpers/analyticsEvents";

const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}

const Hero = ({
                  language,
                  className,
                  topOuterDivider,
                  bottomOuterDivider,
                  topDivider,
                  bottomDivider,
                  hasBgColor,
                  invertColor,
                  ...props
              }) => {

    const [videoModalActive, setVideomodalactive] = useState(false);

    const openModal = (e) => {
        e.preventDefault();
        setVideomodalactive(true);
    }

    const closeModal = (e) => {
        e.preventDefault();
        setVideomodalactive(false);
    }

    const outerClasses = classNames(
        'hero section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'hero-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider',
        "w-100"
    );

    return (
        <section
            {...props}
            className={outerClasses}
            id="hero"
        >
            <div className="hero-content background-hero-header position-relative">
                <div className="container" style={{zIndex: 2}}>
                    <div className="row background-hero-header-row m-auto">
                        <div className="col-md-6 align-self-center mb-5 ps-lg-4">
                            <div className="container-sm background-hero-header-content">
                                <div className="col-md-6 d-blocl d-md-none my-5">
                                    <img src={appShowcase} alt={"Grabbi app"} className="grabbi-intro-image-sm"/>
                                </div>
                                <h1 className="mt-0 mb-3 reveal-from-bottom text-light text-center text-md-start header-hero-text"
                                    data-reveal-delay="200">
                                    {translate('hero.title1.label')}
                                    <span style={{color: "yellow"}}> {translate('hero.title2.label')}</span>
                                </h1>
                                <p className="text-start mb-5">
                                    {translate('hero.text1.label')}
                                </p>

                                <div className="row position-relative">
                                    <h5 className="text-lg-start text-center text-uppercase fw-bold" style={{color: "yellow"}}>
                                        {translate('hero.cta.label')}
                                    </h5>

                                    <div className="d-block mt-4 mb-5 position-relative" style={{zIndex: 9}}>
                                        <div className="container mobile-store-icons m-0">
                                            <div className="row">
                                                <div className="col-sm-12 d-flex flex-row gap-3 justify-content-center justify-content-lg-start">
                                                    <a className="mr-4" href="https://play.google.com/store/apps/details?id=com.grabbi.grabbiapp" onClick={() => clickButton('get_app_android', document.title)}>
                                                        <img className="mb-2" src={getAppGooglePlay}
                                                             alt={"Grabbi app Play Store"} />
                                                    </a>

                                                    <a href="https://apps.apple.com/de/app/grabbi/id1584867620" onClick={() => clickButton('get_app_ios', document.title)}>
                                                        <img className="mb-2" src={getAppIos} alt={"Grabbi app App Store"}/>
                                                    </a>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <img src={arrowImage} alt={"Arrow image"} className="arrow-image" />
                                </div>



                                {/*ROTATING TEXT*/}
                                {/*<div className="d-block">*/}
                                {/*    <RotatingText language={language}/>*/}
                                {/*</div>*/}


                                {/*<div className="container-xs">*/}
                                {/*    <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">*/}

                                {/*    </p>*/}
                                {/*    <div className="reveal-from-bottom" data-reveal-delay="600">*/}
                                {/*        <ButtonGroup>*/}
                                {/*            <Button tag="a" color="primary" wideMobile href="https://cruip.com/">*/}
                                {/*                Get started*/}
                                {/*            </Button>*/}
                                {/*            <Button tag="a" color="dark" wideMobile*/}
                                {/*                    href="https://github.com/cruip/open-react-template/">*/}
                                {/*                View on Github*/}
                                {/*            </Button>*/}
                                {/*        </ButtonGroup>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div className="col-md-6 d-none d-md-block">
                            <img src={appShowcase} alt={"Grabbi app"} className="grabbi-intro-image"/>
                        </div>
                    </div>
                </div>
                <div className="area">
                    <ul className="circles">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </div>
            </div>

        </section>
    );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;