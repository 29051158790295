import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {isIOS, isMacOs, osName} from "react-device-detect";
import Logo from './partials/Logo';
import {LOCALES} from "../../i18n";
import translate from "../../i18n/translate";
import {chooseLanguage, clickButton} from "../../helpers/analyticsEvents";
import {Link} from "react-router-dom";
import shopArrows from "../../assets/images/for_shops_nav_icon.png";

const propTypes = {
    navPosition: PropTypes.string,
    hideNav: PropTypes.bool,
    hideSignin: PropTypes.bool,
    bottomOuterDivider: PropTypes.bool,
    bottomDivider: PropTypes.bool
}

const defaultProps = {
    navPosition: '',
    hideNav: false,
    hideSignin: false,
    bottomOuterDivider: false,
    bottomDivider: false
}

const Header = ({
                    className,
                    navPosition,
                    hideNav,
                    hideSignin,
                    bottomOuterDivider,
                    bottomDivider,
                    language,
                    backgroundTransparent,
                    ...props
                }) => {

    const [isActive, setIsactive] = useState(false);

    const nav = useRef(null);
    const hamburger = useRef(null);

    useEffect(() => {
        isActive && openMenu();
        document.addEventListener('keydown', keyPress);
        document.addEventListener('click', clickOutside);
        return () => {
            document.removeEventListener('keydown', keyPress);
            document.removeEventListener('click', clickOutside);
            closeMenu();
        };
    });

    const openMenu = () => {
        document.body.classList.add('off-nav-is-active');
        nav.current.style.maxHeight = nav.current.scrollHeight + 'px';
        setIsactive(true);
    }

    const closeMenu = () => {
        document.body.classList.remove('off-nav-is-active');
        nav.current && (nav.current.style.maxHeight = null);
        setIsactive(false);
    }

    const keyPress = (e) => {
        isActive && e.keyCode === 27 && closeMenu();
    }

    const clickOutside = (e) => {
        if (!nav.current) return
        if (!isActive || nav.current.contains(e.target) || e.target === hamburger.current) return;
        closeMenu();
    }

    const classes = classNames(
        'site-header',
        bottomOuterDivider && 'has-bottom-divider',
        className
    );

    const handleChange = (event) => {
        language(event.target.value);
        chooseLanguage(event.target.value)
    }

    return (
        <header
            {...props}
            className={classes}
            style={backgroundTransparent && {background: "transparent"}}
        >
            <div className="container">
                <div className={
                    classNames(
                        'site-header-inner',
                        bottomDivider && 'has-bottom-divider'
                    )}

                >
                    <Logo backgroundTransparent={backgroundTransparent} />
                    {!hideNav &&
                    <>
                        <button
                            ref={hamburger}
                            className="header-nav-toggle"
                            onClick={isActive ? closeMenu : openMenu}
                        >
                            <span className="screen-reader">Menu</span>
                            <span className="hamburger">
                  <span className="hamburger-inner"></span>
                </span>
                        </button>
                        <nav
                            ref={nav}
                            className={
                                classNames(
                                    'header-nav',
                                    isActive && 'is-active'
                                )}>
                            <div className="header-nav-inner">
                                {!backgroundTransparent &&
                                    <ul className={classNames('list-reset', 'text-xs', navPosition && `header-nav-${navPosition}`)}>
                                        <li>
                                            <Link
                                                // href="https://grabbi.de/vendor-register" target="_blank"
                                                to="/become-a-partner"
                                                onClick={closeMenu}
                                                className="shop-publish text-light fw-bold d-flex flex-row align-items-center"
                                            >
                                                <span>{translate('nav.publish')}</span>
                                                <img
                                                    src={shopArrows}
                                                    alt="icon for shop publishing"
                                                />
                                            </Link>
                                        </li>
                                    </ul>
                                }
                                {!hideSignin &&
                                <ul className="list-reset header-nav-right">
                                    <li>
                                        <a
                                            onClick={() => {
                                                (isIOS || isMacOs
                                                    ? clickButton("header_get_app_ios", document.title)
                                                    : clickButton("header_get_app_android", document.title))
                                            }}
                                            href={isIOS || isMacOs
                                                ? "https://apps.apple.com/de/app/grabbi/id1584867620"
                                                : "https://play.google.com/store/apps/details?id=com.grabbi.grabbiapp"
                                            }
                                            className="button button-primary button-wide-mobile button-sm hol-dir-die-app"
                                            style={{background: "yellow", color: "black"}}
                                        >
                                            {translate("nav.getapp")}
                                        </a>
                                    </li>
                                </ul>}
                                <div>
                                    <select className="language-dropdown" onChange={handleChange}>
                                        <option value={LOCALES.DEUTSCH} name="de-DE">DE</option>
                                        <option value={LOCALES.ENGLISH} name="en-EN">EN</option>
                                    </select>
                                </div>
                            </div>
                        </nav>
                    </>}
                </div>
            </div>
        </header>
    );
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
