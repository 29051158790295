import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import LayoutPages from "../layouts/LayoutPages";
import {LOCALES} from "../i18n";

const Impressum = () => {

    useEffect(() => {
        document.title = "Impressum"
    }, []);

    const [locale, setLocale] = useState(LOCALES.DEUTSCH);

    function changeLanguage(lang) {
        setLocale(lang)
    }

    const location = useLocation();

    return (
        <LayoutPages locale={locale} language={changeLanguage}>
            <section id="impressum">
                <div className="container mt-5">
                    <h2>Impressum</h2>
                    <p>Grabbi UG<br/>
                        Hönower Str. 35<br/>10318 Berlin</p>
                    <p>Telefon: +4915731194311<br/>
                        E-Mail: <a href="mailto:info@grabbi.de">info@grabbi.de</a><br/>
                    </p>
                    <p><strong>Vertreten durch:</strong><br/>Geschäftsführer Eric Lehmann</p><p>
                    <strong>Bildrechte:</strong><br/>Icons created by Freepik - Flaticon<br/>
                    Photos by Unsplash</p>
                    <br/><br/><h2>Disclaimer – rechtliche Hinweise</h2>
                    § 1 Warnhinweis zu Inhalten<br/>
                    Die kostenlosen und frei zugänglichen Inhalte dieser Webseite wurden mit größtmöglicher Sorgfalt erstellt.
                    Der Anbieter dieser Webseite übernimmt jedoch keine Gewähr für die Richtigkeit und Aktualität der
                    bereitgestellten kostenlosen und frei zugänglichen journalistischen Ratgeber und Nachrichten. Namentlich
                    gekennzeichnete Beiträge geben die Meinung des jeweiligen Autors und nicht immer die Meinung des Anbieters
                    wieder. Allein durch den Aufruf der kostenlosen und frei zugänglichen Inhalte kommt keinerlei
                    Vertragsverhältnis zwischen dem Nutzer und dem Anbieter zustande, insoweit fehlt es am Rechtsbindungswillen
                    des Anbieters.<br/>
                    <br/>
                    § 2 Externe Links<br/>
                    Diese Website enthält Verknüpfungen zu Websites Dritter ("externe Links"). Diese Websites unterliegen der
                    Haftung der jeweiligen Betreiber. Der Anbieter hat bei der erstmaligen Verknüpfung der externen Links die
                    fremden Inhalte daraufhin überprüft, ob etwaige Rechtsverstöße bestehen. Zu dem Zeitpunkt waren keine
                    Rechtsverstöße ersichtlich. Der Anbieter hat keinerlei Einfluss auf die aktuelle und zukünftige Gestaltung
                    und auf die Inhalte der verknüpften Seiten. Das Setzen von externen Links bedeutet nicht, dass sich der
                    Anbieter die hinter dem Verweis oder Link liegenden Inhalte zu Eigen macht. Eine ständige Kontrolle der
                    externen Links ist für den Anbieter ohne konkrete Hinweise auf Rechtsverstöße nicht zumutbar. Bei Kenntnis
                    von Rechtsverstößen werden jedoch derartige externe Links unverzüglich gelöscht.<br/>
                    <br/>
                    § 3 Urheber- und Leistungsschutzrechte<br/>
                    Die auf dieser Website veröffentlichten Inhalte unterliegen dem deutschen Urheber- und Leistungsschutzrecht.
                    Jede vom deutschen Urheber- und Leistungsschutzrecht nicht zugelassene Verwertung bedarf der vorherigen
                    schriftlichen Zustimmung des Anbieters oder jeweiligen Rechteinhabers. Dies gilt insbesondere für
                    Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten in
                    Datenbanken oder anderen elektronischen Medien und Systemen. Inhalte und Rechte Dritter sind dabei als
                    solche gekennzeichnet. Die unerlaubte Vervielfältigung oder Weitergabe einzelner Inhalte oder kompletter
                    Seiten ist nicht gestattet und strafbar. Lediglich die Herstellung von Kopien und Downloads für den
                    persönlichen, privaten und nicht kommerziellen Gebrauch ist erlaubt.<br/>
                    <br/>
                    Die Darstellung dieser Website in fremden Frames ist nur mit schriftlicher Erlaubnis zulässig.<br/>
                    <br/>
                    § 4 Besondere Nutzungsbedingungen<br/>
                    Soweit besondere Bedingungen für einzelne Nutzungen dieser Website von den vorgenannten Paragraphen
                    abweichen, wird an entsprechender Stelle ausdrücklich darauf hingewiesen. In diesem Falle gelten im
                    jeweiligen Einzelfall die besonderen Nutzungsbedingungen.<p>Quelle: <a
                    href="https://www.juraforum.de/impressum-generator/">Klick hier</a></p>
                    <br/>
                    § 5 Bildnachweis<br/>
                    <p>Photo by <a href="https://unsplash.com/@kapischka?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Paul Kapischka</a> on <a href="https://unsplash.com/s/photos/berlin-shop?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></p>
                    <p>Photo by <a href="https://unsplash.com/@claybanks?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Clay Banks</a> on <a href="https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></p>
                    <a href="https://www.flaticon.com/" title="Freepik / Flaticon">Icons created by Freepik - Flaticon</a>
                </div>

            </section>

        </LayoutPages>
    );
};

export default Impressum;
