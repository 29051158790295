import React, {useState} from 'react';
import axios from "axios";
import translate from "../../i18n/translate";

const ContactFormVendorsAlternative = () => {

    const [name, setName] = useState("");
    const [shop, setShop] = useState("");
    const [tel, setTel] = useState("");
    const [email, setEmail] = useState("");

    const [submitting, setSubmitting] = useState(false);

    function handleSubmit() {

        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let regTel = /^[0-9]*$/;


        if (!name || !shop || !tel || !email) {
            alert("Bitte gib alle die Information ein")
        } else if (!regTel.test(tel)) {
            alert("Bitte gib eine gültige Telefonnummer")
        } else if (!regEmail.test(email)) {
            alert("Bitte gib eine gültige E-Mail Adresse")
        } else {
            setSubmitting(true);

            const bodyFormData = new FormData();

            bodyFormData.append('person-name', name)
            bodyFormData.append('shop', shop)
            bodyFormData.append('tel', tel)
            bodyFormData.append('email', email)

            axios({
                method: "post",
                url: "https://grabbi.de/wp-json/contact-form-7/v1/contact-forms/19096/feedback",
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data" },
            })
                .then(function (response) {
                    //handle success
                    console.log(response);
                    setSubmitting(false);
                    alert(response.data.message);
                    setName("");
                    setEmail("");
                    setTel("");
                    setShop("");

                })
                .catch(function (response) {
                    //handle error
                    console.log(response);
                    setSubmitting(false);
                    alert("Es gab ein Fehler");
                    setName("");
                    setEmail("");
                    setTel("");
                    setShop("");
                });
        }
    }


    function renderForm() {
        return (
            <div className="container">
                <label className="mb-2 col-12">
                    <span className="fw-bold mb-2 ps-1">
                        {translate("vendor.why-us.contact-from.field1")}
                    </span>
                    <input
                        className="col form-control"
                        type="text"
                        value={name}
                        required
                        onChange={(e) => setName(e.target.value)}
                    />
                </label>
                <label className="mb-2 col-12">
                    <span className="fw-bold mb-2 ps-1">
                        {translate("vendor.why-us.contact-from.field2")}
                    </span>
                    <input
                        className="col form-control"
                        type="text"
                        value={shop}
                        required
                        onChange={(e) => setShop(e.target.value)}
                    />
                </label>
                <label className="mb-2 col-12">
                    <span className="fw-bold mb-2 ps-1">
                        {translate("vendor.why-us.contact-from.field3")}
                    </span>
                    <input className="col form-control"
                           type="tel"
                           value={tel}
                           required
                           onChange={(e) => setTel(e.target.value)}
                    />
                </label>
                <label className="mb-2 col-12">
                    <span className="fw-bold mb-2 ps-1">
                        {translate("vendor.why-us.contact-from.field4")}
                    </span>
                    <input className="col form-control"
                           type="email"
                           value={email}
                           required
                           onChange={(e) => setEmail(e.target.value)}
                    />
                </label>
                <button
                    onClick={!submitting ? handleSubmit : null}
                    className="mt-4"
                    style={submitting ? {backgroundColor: "lightgray", color: "3D3D3D"} : {}}
                >
                    {translate("vendor.why-us.contact-from.sendButton")}
                </button>
            </div>
        )
    }

    return renderForm();
};

export default ContactFormVendorsAlternative;