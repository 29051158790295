import React, {useState} from 'react';
// import sections
import Hero from '../components/sections/Hero';
import Cta from '../components/sections/Cta';
import {LOCALES} from '../i18n/index';
import LayoutDefault from "../layouts/LayoutDefault";
import {useLocation} from "react-router-dom";
import WhoWeAre from "../components/sections/WhoWeAre";
import VendorsSection from "../components/sections/WhatWeDo";
import HowItWorks from "../components/sections/HowItWorks";
import VendorsCall from "../components/sections/VendorsCall";
import WhatWeDo from "../components/sections/WhatWeDo";
import CtaVendorsBanner from "../components/sections/CtaVendorsBanner";
import ContactFormVendors from "../components/sections/ContactFormVendors";



const Home = (props) => {

    const [locale, setLocale] = useState(LOCALES.DEUTSCH);

    function changeLanguage(lang) {
        setLocale(lang)
    }

    let location = useLocation();

    return (
        <LayoutDefault locale={locale} language={changeLanguage}>
            <Hero language={locale} className="hero-main"/>
            <WhatWeDo
                language={locale}
                invertMobile
                topDivider
                imageFill
                className="illustration-section-02"
            />
            <CtaVendorsBanner language={locale} />
            <WhoWeAre
                language={locale}
                location={location}
            />
            <HowItWorks />
            <Cta language={locale} split/>


            {/*CONTACT FORM MODAL*/}
            <div className="modal" tabIndex="-1" id="vendorContactFormModal">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Modal title</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <ContactFormVendors />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary">Save changes</button>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutDefault>
    )
}

export default Home;