import {LOCALES} from "../locales";

export default {
    [LOCALES.ENGLISH]: {
        "nav.publish": "For shop owners",
        "nav.getapp": "Get the app",

        "hero.title1.label": "We connect the local Berlin retail ",
        "hero.title2.label": "in one app.",
        "hero.text1.label": "Discover new shops in minutes, find unique products and have your order delivered to your home within the same day.",
        "hero.cta.label": "Download and get started",

        "whatwedo.h1-1": "Your digital marketplace for ",
        "whatwedo.h1-2": "local Berlin shops.",
        "whatwedo.h4": "What we do",
        "whatwedo.threecolumns.h4": "Our value porpositions",
        "whatwedo.threecolumns.h1": "We help to shape the cityscape in a sustainable way",

        "whatwedo.column1.title": "Carbon neutral delivery",
        "whatwedo.column1.p": "With an exclusive delivery by bicycle or alternative electromobility, we guarantee climate-neutral transport or shipping of all orders.",
        "whatwedo.column2.title": "Building a community",
        "whatwedo.column2.p": "It is important to us that we help shape urban space in a sustainable way and contribute to preserving it in the long term. We want to support local retailers and make them more competitive.",
        "whatwedo.column3.title": "No extra packaging",
        "whatwedo.column3.p": "Unnecessary rubbish on delivery? Does not have to be! If you order from Grabbi, you will receive your product with no additional packaging.",
        "whatWeDo.paragraph": "Grabbi is much more than just a traditional delivery service. We give local retail a digital face. Browse stores in your neighborhood and many others from the comfort of your own home, support retailers all over Berlin and receive your order the same day.",

        "vendorsBanner.h2": "You own a shop in the city?",
        "vendorsBanner.button": "Register now",

        "withoutGrabbi.h4": "Why Grabbi",
        "withoutGrabbi.p": "The sales area is one of the decisive success factors in stationary retail. If the distance between the shop and the customer's own home is too big, many opt for a closer or digital alternative.",
        "withGrabbi.p": "Grabbi helps you to expand your customer radius. With our service, you have the opportunity to reach significantly more people throughout Berlin, increase your number of customers and boost your sales.",

        "vendor.header.h1": "Reach new customers, expand your reach and get more orders.",
        "vendor.header.button": "Register now",
        "vendor.why-us.h4.1": "WHY GRABBI",
        "vendor.why-us.p.1": "The sales area is one of the decisive success factors in stationary retail. If the distance between the shop and the customer's own home is too long, many opt for a closer or digital alternative.",
        "vendor.why-us.p.2": "Grabbi helps you to expand your customer radius. With our service, you have the opportunity to reach significantly more people throughout Berlin, increase your number of customers and boost your sales.",
        "vendor.why-us.column1.h1": "Increase your reach",
        "vendor.why-us.column1.p": "Have your products delivered to new parts of the city, expand your reach and attract many new customers to your business.",
        "vendor.why-us.column2.h1": "All orders of the picked up at once",
        "vendor.why-us.column2.p": "The orders are only picked up in a fixed time window once a day. This allows you to plan accordingly and your additional effort remains minimal.",
        "vendor.why-us.column3.h1": "No fixed costs, no risks.",
        "vendor.why-us.column3.p": "Grabbi works on a commission basis. There are no fixed costs and you can end the cooperation with us at any time.",
        "vendor.why-us.how-it-works.h4": "How it works",
        "vendor.why-us.how-it-works.p": "There are only three steps until your customers receive their orders. All you need is your own shop or an accessible warehouse and available digital data on your products (including pictures, description, price).",
        "vendor.why-us.how-it-works.card1.title": "Notification",
        "vendor.why-us.how-it-works.card1.p": "At 4pm you will receive the exact information about the orders.",
        "vendor.why-us.how-it-works.card2.title": "Preparation",
        "vendor.why-us.how-it-works.card2.p": "You prepare the products according to the notification.",
        "vendor.why-us.how-it-works.card3.title": "Pick-up",
        "vendor.why-us.how-it-works.card3.p": "The order will be collected from your shop between 4 - 6 pm and delivered by us.",
        "vendor.why-us.contact-from.h4": "Become a Grabbit",
        "vendor.why-us.contact-from.h1.1": "You think Grabbi is the right choice for you and your shop?",
        "vendor.why-us.contact-from.h1.2": " Then get in touch with us!",
        "vendor.why-us.contact-from.field1": "Your name",
        "vendor.why-us.contact-from.field2": "Name of your shop",
        "vendor.why-us.contact-from.field3": "Phone number",
        "vendor.why-us.contact-from.field4": "Email address",
        "vendor.why-us.contact-from.sendButton": "Send message",

        "vendors.column1.p": "Löse geographische Grenzen auf, erweitere deine Reichweite um ein Vielfaches und gewinne so neue Kund:innen",
        "vendors.column2.p": "Wir kommen nur einmal am Tag zur gleichen Zeit vorbei und holen alle Bestellungen auf einmal ab. So kannst du deinen Tag entsprechend planen.",
        "vendors.column3.p": "Unser Model funktioniert auf Provisionsbasis. Du hast keine Fixkosten und kannst jederzeit diie Zusammenarbeit mit uns beenden.",


        "hero.listTitle1": "LOCAL PRODUCTS",
        "hero.listSubTitle1": "Support local shops in the city.",
        "hero.listTitle2": "SAME-DAY DELIVERY",
        "hero.listSubTitle2": "Get products that you love in the same day.",
        "hero.listTitle3": "SUSTAINABLE",
        "hero.listSubTitle3": "Delivered sustainably. No extra packaging.",

        "hero.appShowcase.title": "DIRECT DELIVERY FROM SHOPS IN YOUR NEIGHBOURHOOD",
        "hero.appShowcase.list1": "Get your favourite products delivered within the day",
        "hero.appShowcase.list2": "Discover new shops and get inspired by unique products",
        "hero.appShowcase.list3": "No extra packaging included in our deliveries",
        "hero.appShowcase.list4": "We support local shops by promoting their products in our platform",
        "hero.bssText": "Grabbi is sponsored from 01.12.2021 till 01.12.2022 by the InnoTechHub from Hochschule für Technik und Wirtschaft.",

        "who-we-are.h4": "OUR PHILOSOPHY",
        "who-we-are.h1.2a": "Experience the ",
        "who-we-are.h1.2b": "charm",
        "who-we-are.h1.2c": " ",
        "who-we-are.h1.2d": "of Berlin",

        "who-we-are.h1.1a": "Connecting people ",
        "who-we-are.h1.1b": " ",
        "who-we-are.h1.1c": "with local shops",

        "who-we-are.paragraph": "At Grabbi, we believe that local shops constitute the heart of the city. The local stores ecosystem shapes the perception of urban space, and imprints a strong cultural context throughout the different neighbourhoods across the city. Retail tells stories of people who make each store unique and play a key role in shaping the cityscape.",
        "who-we-are.paragraph2": "We see ourselves as a link or facilitator between retail and people. Want to bring communities together through digitalisation and provide an alternative way of personalising the shopping experience in an era where online shopping is increasingly dominating. Our goal is to preserve the charm of the city and especially, in times of crisis to stick together as a community.",

        "featureTiles.title": "Connecting Berlin",
        "featureTiles.subTitle": "A digital shopping mall of your neighbourhood with same-day delivery",
        "featureTiles.paragraph": "We believe that digitalisation can bring communities together. We want to use mobile purchasing technology to give local shops a new competitie edge, in a time where people are relying on on-demand platforms for their needs. Therefore, our focuse in Grabbi is to bring the feeling of the Kiez once again, in a digital form.",
        "featureTiles.button": "View all brands",

        "operations.subtitle": "How it works",
        "operations.title": "From local shops, directly to your house",
        "operations.browse": "1. Browse",
        "operations.browse.text": "Find and discover products from local shops around you",
        "operations.order": "2. Order",
        "operations.order.text": "Easily order on your phone and pay securely",
        "operations.receive": "3. Receive",
        "operations.receive.text": "Receive the ordered products within the same day",

        "testimonials.title": "People supporting our vision",
        "testimonials.subTitle": "Read about our customers' experience using our services",
        "testimonials.text1": "— I come from Mexico and love to cook international dishes. Grabbi helped me discover new stores that offer exactly the ingredients I need. Even though I have no time after work to shop personally, I can get the goods delivered to my doorstep within the same day!",
        "testimonials.position1": "Programmer",
        "testimonials.text2": "— Since I joined Grabbi, not only I've gained new customers, but I've also managed to improve my service with same-day delivery and without any extra effort. Plus, they took care of the whole onboarding process.",
        "testimonials.position2": "Shop owner",
        "testimonials.text3": "— Through Grabbi I can support my local community, and contribute to a more sustainable buying system. I was also able to discover many new unique products in my Kiez.",
        "testimonials.position3": "Consultant",

        "how-it-works.h4": "How it works",
        "how-it-works.h1": "Order in 3 simple steps",

        "how-it-works.item.title1": "SEARCH",
        "how-it-works.item.text1": "Explore and discover shops in the city.",

        "how-it-works.item.title2": "ORDER",
        "how-it-works.item.text2": "Choose your favourite products and place the order.",

        "how-it-works.item.title3": "RECEIVE",
        "how-it-works.item.text3": "Same day delivery to your doorstep!",

        "cta.title": "Want to get the latest updates?",
        "cta.subTitle": "Subscribe now",
        "cta.textInput": "Your email",
        "cta.subscribeButton": "Subscribe",

        "get-the-app.h3": "Get the app now",
        "get-the-app.h1.1": "Discover your neighbourhood.",
        "get-the-app.h1.2": "Buy local.",
        "get-the-app.h2.1": "Discover your neighbourhood.",
        "get-the-app.h2.2": "Buy local.",
        "get-the-app.p": "We are live in many parts of your city and already deliver to Mitte, Moabit, Prenzlauer Berg, Friedrichshain and Kreuzberg.",

        "footer.list1": "Contact",
        "footer.list2": "About us",
        "footer.list3": "FAQ",
        "footer.list4": "Support",

        "footer.list2-1": "Privacy Policy",
        "footer.list2-2": "Terms & Conditions",
        "footer.list2-3": "Become a partner",
        "footer.list2-4": "Impressum",

        "footer.copyright": "Developed by Eric Lehmann"
    }
}