import React, {useEffect} from 'react';
import classNames from 'classnames';
import {SectionTilesProps} from '../../utils/SectionProps';
import BrandImagesScroll from '../elements/BrandImagesScroll';
import translate from "../../i18n/translate";
import {isIOS, isMacOs} from "react-device-detect";
import Parallax from "react-rellax";

const propTypes = {
    ...SectionTilesProps.types
}

const defaultProps = {
    ...SectionTilesProps.defaults
}
const WhoWeAre = ({
                      className,
                      location,
                      language,
                      topOuterDivider,
                      bottomOuterDivider,
                      topDivider,
                      bottomDivider,
                      hasBgColor,
                      invertColor,
                      pushLeft,
                      ...props
                  }) => {

    const outerClasses = classNames(
        'features-tiles section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    return (
        <section
            {...props}
            className={outerClasses}
            id="who-we-are"
        >
            <div className="brands-section-container full-screen-height">
                <div className="container px-3 py-5">
                    <div className="row">
                        {/*FIRST TEXT*/}
                        <div className="col-md-6 align-self-center text-md-start text-center">
                            <div className="row m-auto mb-4 justify-content-center" style={{maxWidth: 1000}}>
                                <h4 className="mb-4" style={{color: "yellow"}}>{translate("who-we-are.h4")}</h4>
                                {/*FIRST TITLE FUNKY STYLED*/}
                                <div className="d-none d-xl-block">
                                    <h1 className="mb-1">
                                    <span className="grabbi-color-2 fw-500" style={{
                                        color: "black",
                                        backgroundColor: "rgba(206,167,224,0.6)",
                                        padding: "0 30px"
                                    }}>
                                        {translate("who-we-are.h1.2a")}
                                        <span style={{color: "#fff"}}>{translate("who-we-are.h1.2b")}</span>
                                        {translate("who-we-are.h1.2c")}
                                    </span>
                                    </h1>
                                    <h1 className="mb-3">
                                    <span className="grabbi-color-2 fw-500" style={{
                                        color: "black",
                                        backgroundColor: "rgba(206,167,224,0.6)",
                                        padding: "0 30px",
                                        marginLeft: 100
                                    }}>
                                        {translate("who-we-are.h1.2d")}
                                    </span>
                                    </h1>
                                </div>
                                {/*FIRST TITLE NORMAL STYLED*/}
                                <div className="d-block d-xl-none pb-4 mb-2">
                                    <h1 className="mb-1">
                                        {language === "de-de" ? (
                                            <div>
                                               <span className="grabbi-color-2 fw-500 h1-titles">
                                                    Den <span style={{color: "#fff"}}>Charme</span>
                                                </span>
                                                <br/>
                                                <span className="grabbi-color-2 fw-500 h1-titles ms-md-1">
                                                    von Berlin
                                                </span>
                                                <br/>
                                                <span className="grabbi-color-2 fw-500 h1-titles">
                                                    erhalten
                                                </span>
                                            </div>
                                            ) : (
                                            <div>
                                               <span className="grabbi-color-2 fw-500 h1-titles">
                                                    Experience the
                                                </span>
                                                <br/>
                                                <span className="grabbi-color-2 fw-500 h1-titles ms-md-1">
                                                    <span style={{color: "#fff"}}>charm</span> of
                                                </span>
                                                <br/>
                                                <span className="grabbi-color-2 fw-500 h1-titles">
                                                    Berlin
                                                </span>
                                            </div>
                                            )}

                                    </h1>
                                </div>
                                {/*FIRST PICTURE*/}
                                <div className="d-md-none w-75 mb-5 mt-3">
                                    {/*<Parallax speed={0.5}>*/}
                                    <img
                                        className="rellax2"
                                        src={require('../../assets/images/how_it_works_rider.jpg')}
                                        alt="Delivery driver"
                                    />
                                    {/*</Parallax>*/}
                                </div>
                                <p>{translate('who-we-are.paragraph')}</p>
                            </div>
                        </div>

                        <div className="col-md-6 mb-md-2 mb-4 justify-content-center d-flex">
                            <div className="container-stack" id="who-we-are-images">
                                <div className="image-stack">
                                    <div className="image-stack__item image-stack__item--bottom d-none d-md-block">
                                        {/*<Parallax speed={-1}>*/}
                                        <img
                                            className="rellax1"
                                            src={require('../../assets/images/local_stores.jpg')}
                                            alt="Local store"
                                        />
                                        {/*</Parallax>*/}
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row">

                        <div className="col-md-6 mb-md-2 mb-4 d-flex justify-content-center">
                            <div className="container-stack" id="who-we-are-images">
                                <div className="image-stack">
                                    <div className="image-stack__item image-stack__item--top d-none d-md-block">
                                        {/*<Parallax speed={0.5}>*/}
                                        <img
                                            className="rellax2"
                                            src={require('../../assets/images/how_it_works_rider.jpg')}
                                            alt="Delivery driver"
                                        />
                                        {/*</Parallax>*/}

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 align-self-center text-md-start text-center">
                            {/*SECOND TITLE FUNKY*/}
                            <div className="d-none d-xl-block">
                                <h1 className="mb-1">
                                    <span className="grabbi-color-2 fw-500" style={{
                                        color: "black",
                                        backgroundColor: "rgba(206,167,224,0.6)",
                                        padding: "0 30px"
                                    }}>
                                        <span style={{color: "#fff"}}>{translate("who-we-are.h1.1a")}</span>
                                        {translate("who-we-are.h1.1b")}
                                    </span>
                                </h1>
                                <h1 className="mb-3">
                                    <span className="grabbi-color-2 fw-500" style={{
                                        color: "black",
                                        backgroundColor: "rgba(206,167,224,0.6)",
                                        padding: "0 30px",
                                        marginLeft: 25
                                    }}>
                                        {translate("who-we-are.h1.1c")}
                                    </span>
                                </h1>
                            </div>
                            {/*SECOND TITLE NORMAL STYLED*/}
                            <div className="d-block d-xl-none pb-4 mb-2">
                                <h1 className="mb-1">
                                    <br/>
                                    {language === "de-de" ? (
                                        <div>
                                             <span style={{color: "#fff"}} className="grabbi-color-2 fw-500 h1-titles">
                                                Lokale Shops
                                            </span>
                                            <br/>
                                            <span className="grabbi-color-2 fw-500 h1-titles ms-md-1">
                                                mit Menschen
                                            </span>
                                            <br/>
                                            <span className="grabbi-color-2 fw-500 h1-titles">
                                                verbinden
                                            </span>
                                        </div>
                                    ) : (
                                        <div>
                                             <span className="grabbi-color-2 fw-500 h1-titles">
                                                Connecting
                                            </span>
                                            <br/>
                                            <span className="grabbi-color-2 fw-500 h1-titles ms-md-1">
                                                <span style={{color: "#fff"}}>people</span> with
                                            </span>
                                            <br/>
                                            <span className="grabbi-color-2 fw-500 h1-titles">
                                                local shops
                                            </span>
                                        </div>
                                    )
                                    }

                                </h1>
                            </div>
                            <div className="d-md-none w-75 mb-5 mt-3 mx-auto">
                                {/*<Parallax speed={0.5}>*/}
                                <img
                                    className="rellax2"
                                    src={require('../../assets/images/local_stores.jpg')}
                                    alt="Delivery driver"
                                />
                                {/*</Parallax>*/}
                            </div>
                            <p>{translate('who-we-are.paragraph2')}</p>
                        </div>

                    </div>

                    {/*<div className="text-light container-sm" style={{marginBottom: 65}}>*/}
                    {/*    <div className="text-center text-sm-start" style={{marginBottom: 75, marginTop: 30}}>*/}
                    {/*        <h2 className="grabbi-color-2 pb-3">*/}
                    {/*            {translate('featureTiles.title')}*/}
                    {/*        </h2>*/}
                    {/*        <h2 className="text-light px-3">*/}
                    {/*            {translate('featureTiles.subTitle')}*/}
                    {/*        </h2>*/}
                    {/*    </div>*/}
                    {/*    <BrandImagesScroll location={location}/>*/}
                    {/*</div>*/}
                    {/*<div className="row text-center">*/}
                    {/*    <div className="col">*/}
                    {/*        <a href={isIOS || isMacOs*/}
                    {/*            ? "https://apps.apple.com/de/app/grabbi/id1584867620"*/}
                    {/*            : "https://play.google.com/store/apps/details?id=com.grabbi.grabbiapp"*/}
                    {/*        }>*/}
                    {/*            <button>*/}
                    {/*                {translate('featureTiles.button')}*/}
                    {/*            </button>*/}
                    {/*        </a>*/}

                    {/*    </div>*/}
                    {/*</div>*/}

                </div>
            </div>

        </section>
    );
}

WhoWeAre.propTypes = propTypes;
WhoWeAre.defaultProps = defaultProps;

export default WhoWeAre;